<template>
  <form @submit.prevent="onsubmit" >
    <va-input
      v-model="username"
      type="username"
      label="username"
      :error="!!usernameErrors.length"
      :error-messages="usernameErrors"
    />

    <va-input
      v-model="password"
      type="password"
      label="password"
      :error="!!passwordErrors.length"
      :error-messages="passwordErrors"
    />
    <!--
    <div class="auth-layout__options d-flex align--center justify--space-between">
      <va-checkbox v-model="keepLoggedIn" class="mb-0" :label="$t('auth.keep_logged_in')" /> 
      <router-link
        class="ml-1 link"
        :to="{name: 'recover-password'}"
      >{{$t('auth.recover_password')}}</router-link>
    </div>
    -->
    <div class="d-flex justify--center mt-3">
      <va-button type="submit" class="my-0" @click="onsubmit">{{ $t('auth.login') }}</va-button>
    </div>
  </form>
</template>

<script>
export default {
  name: "login",
  data() {
    return {
      username: "",
      password: "",
      keepLoggedIn: false,
      usernameErrors: [],
      passwordErrors: [],
      toastText: '',
      toastDuration: 2500,
      toastPosition: 'top-center',
      isToastFullWidth: false,
    };
  },
  computed: {
    formReady() {
      return !this.usernameErrors.length && !this.passwordErrors.length;
    },
  },
  methods: {
   
    onsubmit() {

      this.usernameErrors = this.username ? [] : ["username is required"];
      this.passwordErrors = this.password ? [] : ["Password is required"];
      if (this.formReady) {

        this.axios
            .post("/v1/auth/login", {username: this.username, password: this.password
            })
            .then((res) => {
            // console.log(res.data["access_token"]);
            // console.log(res.data["refresh_token"]);

            sessionStorage.clear()
            // sessionStorage['userid']=JSON.stringify(res.data.userInfo.id)
            sessionStorage.setItem('username',this.username)
            sessionStorage['user_role']=JSON.stringify(res.data["user_role"])
            sessionStorage['access_token']=JSON.stringify(res.data["access_token"])
            sessionStorage['refresh_token']=JSON.stringify(res.data["refresh_token"])
            this.$router.push({ name: "shpage" });
            // this.$router.push({ path: this.redirect || name: "/admin/shpage" });
            })
            .catch((error) =>{
                if(error.response.status===401)
                {
                    this.toastText="请检查账号密码是否正确";
                    this.showToast(this.toastText, {
                    position: this.toastPosition,
                    duration: this.toastDuration,
                    fullWidth: this.isToastFullWidth,
                    });
                }
            });

      } 
    },

  },
};
</script>

<style lang="scss">
</style>
